import { AuthContext } from "providers/AuthProvider"
import React, { useContext, useMemo, useState } from "react"
import Modal from "./Modal"
import LoaderButton from "./widgets/LoaderButton"
import Shift from "../interfaces/Shift"
import moment, { Moment } from "moment"
import useFeatureSwitches from "../hooks/useFeatureSwitches"
import { addMomentTimesToShift } from "./ShiftActions/tools"
import stopIcon from "../assets/stop.png"
import { instanceToTzMoment, SERVER_DATETIME_FORMAT } from "./timezone/TimezoneConversion"

interface Props {
    onConfirmAttestationNotice: (attestationNotice: AttestationNotice) => void
    shift: Shift
    actualStopTime: Moment
    loading: boolean
}

export enum AttestationNotice {
    YES = "YES",
    WAIVERED = "WAIVERED",
    NO = "NO",
    NO_RESPONSE = "NO_RESPONSE"
}

const noResponseWaitSec = 10

export default function AttestationNoticeModal({ onConfirmAttestationNotice, shift, actualStopTime, loading }: Props) {
    const { Message } = useContext(AuthContext)
    const [tookAllocatedBreaks, setTookAllocatedBreaks] = useState<AttestationNotice>(AttestationNotice.YES)
    const { timezonesEnabled } = useFeatureSwitches()
    const modifiedShift = useMemo(() => addMomentTimesToShift(shift, timezonesEnabled), [shift, timezonesEnabled])
    const actualStopTimeTz = useMemo(() => instanceToTzMoment(actualStopTime.format(SERVER_DATETIME_FORMAT), modifiedShift.siteTimezoneId,
        modifiedShift.instanceTimezoneId,
        timezonesEnabled) || moment(), [actualStopTime, modifiedShift, timezonesEnabled])
    
    const shiftDuration = moment.duration(actualStopTimeTz.diff(modifiedShift.actStartTimeTz))

    return (
        <Modal 
            onAutoAdvanceOnClick={() => onConfirmAttestationNotice(AttestationNotice.NO_RESPONSE)}
            autoAdvanceLoading={loading}
            autoAdvanceClickInMs={1000 * noResponseWaitSec}
            autoAdvanceButtonText={Message("nextWorker")}
            autoAdvanceMessageText={"sessionEnd"}
        >
            <div className="attestationNoticeModal">
                <div className="icon">
                    <img src={stopIcon} alt="" />
                </div>
                <div className="header1">
                    {Message("shiftStopped", [actualStopTimeTz.format("HH:mm")])}
                </div>
                <div className="header2">
                    {Message("shiftDuration", [parseInt(shiftDuration.asHours().toString()).toString(),(shiftDuration.asMinutes() % 60).toString()])}
                </div>
                <div className="header3">
                    {Message("attestationNoticeStatement")}
                </div>
                <div className="header4">
                    {Message("attestationNoticeConfirm")}
                </div>
                
                <select name="attestationNotice"
                    onChange={(e) =>
                        setTookAllocatedBreaks(AttestationNotice[e.target.value as keyof typeof AttestationNotice])
                    }
                    value={tookAllocatedBreaks}>
                    {Object.keys(AttestationNotice).filter((key) => key !== AttestationNotice.NO_RESPONSE).map((key) => (
                        <option key={key} value={key}>
                            {Message("attestationNotice_" + key)}
                        </option>
                    ))}
                </select>
    
                <LoaderButton
                    disabled={!tookAllocatedBreaks}
                    onClick={() => onConfirmAttestationNotice(tookAllocatedBreaks)}
                    loading={loading}
                    className="wider"
                >
                    {Message("confirm")}
                </LoaderButton>
            </div>
        </Modal>
    )
}
